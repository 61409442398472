import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import OurBestsellers from '../../components/OurBestsellers/OurBestsellers';
import { Context } from '../../Context';
import { Helmet } from 'react-helmet-async';
import './Main.css';

export default function Main() {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const { language, data } = useContext(Context);

  const [startVideo, setStartVideo] = useState(false);

  useEffect(() => {
    if (data !== null) setStartVideo(true);
  }, [data]);

  const texts = {
    ukr: {
      more: 'усі товари',
      moreLink: '/all-products',
      newCollection: 'КОЛЕКЦІЯ ”РОЗКУТА”',
      newCollectionLink: '/new-collection',
      shop: 'переглянути',
      collectionOne: 'winter essentials',
      collectionOneDescription: 'ризикніть отримати останні товари',
      collectionOneLink: '/winter-essentials',
      collectionTwo: "Jackets 23' - 24'",
      collectionTwoDescription: 'ризикніть отримати останні товари',
      collectionTwoLink: '/jackets-23-24',
      sale: 'sale up to 50 %',
      saleLink: '/sale',
      seoTitle: 'Брендовий шкіряний одяг для твого гардеробу - Got`s Label',
      seoDescription:
        'В нашому брендовому магазині ви можете купити якісні вироби з натуральної шкіри за оптимальною ціною. Вибирай якість з Готс Лейбл',
      seoH1: 'Шкіряний жіночий одяг',
      seoRu: 'готс',
      seoLang: '',
      lang: 'uk',
    },
    eng: {
      more: 'all products',
      moreLink: '/en/all-products',
      newCollection: 'COLLECTION “UNCHAINED”',
      newCollectionLink: '/en/new-collection',
      shop: 'look',
      collectionOne: 'winter essentials',
      collectionOneDescription: 'take a chance to get last items',
      collectionOneLink: '/en/winter-essentials',
      collectionTwo: "Jackets 23' - 24'",
      collectionTwoDescription: 'take a chance to get last items',
      collectionTwoLink: '/en/jackets-23-24',
      sale: 'sale up to 50 %',
      saleLink: '/en/sale',
      seoTitle: 'Branded leather clothing for your wardrobe - Got`s Label',
      seoDescription:
        'In our brand store, you can buy quality products made of genuine leather at the best price. Choose quality from Got`s Label',
      seoH1: 'Leather women`s clothing',
      seoRu: 'gots',
      seoLang: '/en/',
      lang: 'en',
    },
    pl: {
      more: 'wszystkie towary',
      moreLink: '/pl/all-products',
      newCollection: 'Kolekcja “UNCHAINED”',
      newCollectionLink: '/pl/new-collection',
      shop: 'Patrzeć',
      collectionOne: 'zimowe podstawy',
      collectionOneDescription: 'zaryzykuj, aby zdobyć ostatnie pozycje',
      collectionOneLink: '/pl/winter-essentials',
      collectionTwo: "Jackets 23' - 24'",
      collectionTwoDescription: 'zaryzykuj, aby zdobyć ostatnie pozycje',
      collectionTwoLink: '/pl/jackets-23-24',
      sale: 'sale up to 50 %',
      saleLink: '/pl/sale',
      seoTitle: 'Markowa odzież skórzana do Twojej garderoby - Got`s Label',
      seoDescription:
        'W naszym markowym sklepie możesz kupić wysokiej jakości produkty wykonane z prawdziwej skóry w najlepszej cenie. Wybierz jakość z Got`s Label',
      seoH1: 'Skórzana odzież damska',
      seoRu: 'gots',
      seoLang: '/pl/',
      lang: 'pl',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <main className="main">
      <Helmet>
        <title>{selectedLanguageData.seoTitle}</title>
        <meta name="description" content={selectedLanguageData.seoDescription} />
        <link rel="canonical" href={`https://gotslabel.com${selectedLanguageData.seoLang}`} />

        {/* языки */}
        <html lang={selectedLanguageData.lang} />
        <link rel="alternate" hreflang="uk" href={`https://gotslabel.com/`} />
        <link rel="alternate" hreflang="pl" href={`https://gotslabel.com/pl/`} />
        <link rel="alternate" hreflang="en" href={`https://gotslabel.com/en/`} />
        <link rel="alternate" hreflang="x-default" href={`https://gotslabel.com${selectedLanguageData.seoLang}`} />
      </Helmet>

      <div className="main__video-container">
        {startVideo && (
          <video muted autoPlay preload="auto" loop playsInline className="main__video" controls={false}>
            <source
              src={
                !isMobile
                  ? 'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/video%2Fvideo.MOV?alt=media&token=a861d008-b32e-4dc3-b824-a3e408118818'
                  : 'https://firebasestorage.googleapis.com/v0/b/gots-label.appspot.com/o/video%2Fmob.mov?alt=media&token=62616359-a27a-4ce0-89f4-8cf49a0c14a4'
              }
              type="video/mp4"
            />
          </video>
        )}
        <div className="main__video-text">
          <h1 className="main__video-text-title">{selectedLanguageData.seoH1}</h1>
          <h2 className="main__video-text-title">{selectedLanguageData.seoRu}</h2>
          <Link to={selectedLanguageData.moreLink} className="main__button">
            {selectedLanguageData.more}
          </Link>
        </div>
      </div>
      <div className="main__container-img">
        <div className="main__big-container">
          <img
            src={isMobile ? '/img/collections/new-collection.webp' : '/img/collections/new-collection-mac.webp'}
            alt={selectedLanguageData.newCollection}
          />
          <div className="main__big-container-info">
            <h2 className="main__big-container-info-title">{selectedLanguageData.newCollection}</h2>
            <Link to={selectedLanguageData.newCollectionLink} className="main__big-container-info-button">
              {selectedLanguageData.shop}
            </Link>
          </div>
        </div>
        <div className="main__container-img-couple">
          <div className="main__small-container">
            <img src="/img/collections/winter.webp" alt="WINTER ESSENTIALS" />
            <div className="main__small-container-info">
              <h2 className="main__small-container-info-title">{selectedLanguageData.collectionOne}</h2>
              <Link to={selectedLanguageData.collectionOneLink} className="main__small-container-info-button">
                {selectedLanguageData.shop}
              </Link>
            </div>
            <div className="main__small-container-side-text">{selectedLanguageData.collectionOneDescription}</div>
          </div>
          <div className="main__small-container">
            <img src="/img/collections/jackets.webp" alt="jackets 23'-24'" />
            <div className="main__small-container-info">
              <h2 className="main__small-container-info-title">{selectedLanguageData.collectionTwo}</h2>
              <Link to={selectedLanguageData.collectionTwoLink} className="main__small-container-info-button">
                {selectedLanguageData.shop}
              </Link>
            </div>
            <div className="main__small-container-side-text">{selectedLanguageData.collectionTwoDescription}</div>
          </div>
        </div>
        <div className="main__big-container">
          <img src="/img/collections/sale.webp" alt="SALE UP TO 50%" />
          <div className="main__big-container-info">
            <h2 className="main__big-container-info-title">{selectedLanguageData.sale}</h2>
            <Link to={selectedLanguageData.saleLink} className="main__big-container-info-button">
              {selectedLanguageData.shop}
            </Link>
          </div>
        </div>
      </div>
      <OurBestsellers />
    </main>
  );
}
