export const pathFilters = {
  '/new': {
    newSale: 'new',
    title: 'новинки',
    seoH1: 'Останні надходження',
    seoTitle: 'Останні надходження в ассортименті шкіряних виробів - Got`s Label',
    seoDescription: 'Ознайомся з нашими новинками з останніх колекцій та купуй якісний та стильний шкіряний одяг за справедливою ціною ',
  },
  '/en/new': {
    newSale: 'new',
    title: 'new',
    seoH1: 'Latest Collection',
    seoTitle: 'The latest arrivals in the assortment of leather products - Got`s Label',
    seoDescription: 'Check out our new products from the latest collections and buy quality and stylish leather clothing at a fair price',
  },
  '/pl/new': {
    newSale: 'new',
    title: 'nowości',
    seoH1: 'Ostatnie wpływy',
    seoTitle: 'Nowość w asortymencie wyrobów skórzanych - Got`s Label',
    seoDescription: 'Sprawdź nasze nowości z najnowszych kolekcji i kup wysokiej jakości, stylową odzież skórzaną po uczciwej cenie',
  },
  '/sale': {
    newSale: 'sale',
    title: 'розпродаж',
    seoH1: 'розпродаж',
    seoTitle: 'Актуальні знижки на шкіряні вироби - Got`s Label',
    seoDescription: 'Купуй шкіряні вироби з приємною знижкою та заощаджуй без втрати якості',
  },
  '/en/sale': {
    newSale: 'sale',
    title: 'sale',
    seoH1: 'Our Sales',
    seoTitle: 'Current discounts on leather goods - Got`s Label',
    seoDescription: 'Buy leather goods at a nice discount and save your money without losing quality',
  },
  '/pl/sale': {
    newSale: 'sale',
    title: 'wyprzedaż',
    seoH1: 'Sprzedaż',
    seoTitle: 'Aktualne rabaty na galanterię skórzaną - Got`s Label',
    seoDescription: 'Kupuj wyroby skórzane z atrakcyjnym rabatem i oszczędzaj bez utraty jakości',
  },
  '/bestsellers': {
    bestsellers: true,
    title: 'bestsellers',
    seoH1: 'Наші Бестселлери',
    seoTitle: 'Топ Продажу - Got`s Label',
    seoDescription:
      'Відкрийте світ елегантності з нашими бестселлерами шкіряного одягу. Виберіть з найкращих моделей курток та пальто які поєднують стиль і якість',
  },
  '/en/bestsellers': {
    bestsellers: true,
    title: 'bestsellers',
    seoH1: 'Our bestsellers',
    seoTitle: 'Top Sale - Got`s Label',
    seoDescription:
      'Discover a world of elegance with our bestsellers in leather clothing. Choose from the best models of jackets and coats that combine style and quality',
  },
  '/pl/bestsellers': {
    bestsellers: true,
    title: 'bestsellery',
    seoH1: 'Nasze bestsellery',
    seoTitle: 'Najlepsza sprzedaż - Got`s Label',
    seoDescription:
      'Odkryj świat elegancji dzięki naszym bestsellerom w zakresie odzieży skórzanej. Wybieraj spośród najlepszych modeli kurtek i płaszczy, które łączą w sobie styl i jakość',
  },
  '/jackets': {
    category: 'jackets',
    title: 'куртки',
    seoH1: 'Шкіряні куртки та жакети для вашого гардеробу',
    seoTitle: 'Шкіряні куртки, жакети та косухи відмінної якості - Got`s Label',
    seoDescription: 'Ознайомся з нашим широким ассортиментом курток та жакетів з натуральної шкіри та підбери свій look разом з нами!',
  },
  '/en/jackets': {
    category: 'jackets',
    title: 'jackets',
    seoH1: 'Leather jackets and coats for your wardrobe',
    seoTitle: 'Leather jackets and coats of excellent quality - Got`s Label',
    seoDescription: 'Check out our wide range of jackets and coats made of genuine leather and choose your look with us!',
  },
  '/pl/jackets': {
    category: 'jackets',
    title: 'kurtki',
    seoH1: 'Kurtki skórzane i kurtki do Twojej garderoby',
    seoTitle: 'Skórzane kurtki, kurtki i płaszcze doskonałej jakości - Got`s Label',
    seoDescription: 'Sprawdź naszą bogatą ofertę kurtek i kurtek ze skóry naturalnej i wybierz z nami swój styl!',
  },
  '/bombers': {
    category: 'bombers',
    title: 'бомбери',
    seoH1: 'Якісні та стильні бомбери',
    seoTitle: 'Стильні та яскраві бомбери для твого нового образу - Got`s Label',
    seoDescription:
      'У асортименті нашого бренду ви знайдете великий вибір шкіряних бомберів різних кольорів та фасонів. Знайди свій стиль разом з нами!',
  },
  '/en/bombers': {
    category: 'bombers',
    title: 'bombers',
    seoH1: 'Quality and stylish bomber jackets',
    seoTitle: 'Stylish and bright bomber jackets for your new look - Got`s Label',
    seoDescription:
      'In the assortment of our brand, you will find a large selection of leather bombers in various colors and styles. Find your style with us!',
  },
  '/pl/bombers': {
    category: 'bombers',
    title: 'bombery',
    seoH1: 'Wysokiej jakości i stylowe kurtki bomberki',
    seoTitle: 'Stylowe i jasne kurtki bomberki dla Twojego nowego wyglądu - Got`s Label',
    seoDescription:
      'W asortymencie naszej marki znajdziesz duży wybór skórzanych bomberów w różnych kolorach i fasonach. Znajdź z nami swój styl!',
  },
  '/vests': {
    category: 'vests',
    title: 'жилети',
    seoH1: 'Шкіряні жилети',
    seoTitle: 'Шкіряні жилети для надання шику вашим образам - Got`s Label',
    seoDescription: 'Ознайомся з нашим широким ассортиментом шкіряних жилетів та підбери свій look разом з нами!',
  },
  '/en/vests': {
    category: 'vests',
    title: 'vests',
    seoH1: 'Leather vests',
    seoTitle: 'Leather vests to add chic to your looks - Got`s Label',
    seoDescription: 'Check out our wide range of leather vests and choose your look with us!',
  },
  '/pl/vests': {
    category: 'vests',
    title: 'kamizelki',
    seoH1: 'Kamizelki skórzane',
    seoTitle: 'Kamizelki skórzane, które dodadzą szyku Twojej stylizacji - Got`s Label',
    seoDescription: 'Sprawdź naszą szeroką ofertę kamizelek skórzanych i wybierz z nami swój styl!',
  },
  '/trench': {
    category: 'trench',
    title: 'тренчі',
    seoH1: 'Брендові тренчі ',
    seoTitle: 'Брендові тренчі з якісної шкіри - Got`s Label',
    seoDescription:
      'У асортименті нашого бренду ви знайдете великий вибір тренчів різних кольорів та фасонів. Знайди свій стиль разом з нами!',
  },
  '/en/trench': {
    category: 'trench',
    title: 'trench coats',
    seoH1: 'Branded trench coats',
    seoTitle: 'Branded trench coats made of high-quality leather - Got`s Label',
    seoDescription:
      'In the assortment of our brand, you will find a large selection of trench coats of various colors and styles. Find your style with us!',
  },
  '/pl/trench': {
    category: 'trench',
    title: 'płaszcze trencze',
    seoH1: 'Markowe trencze',
    seoTitle: 'Markowe trencze wykonane z wysokiej jakości skóry - Got`s Label',
    seoDescription: 'W asortymencie naszej marki znajdziesz duży wybór trenczy o różnorodnej kolorystyce i kroju. Znajdź z nami swój styl!',
  },
  '/sheepdogs': {
    category: 'sheepdogs',
    title: 'дублянки',
    seoH1: 'Шкіряні дублянки',
    seoTitle: 'Дублянки з натуральної шкіри - Got`s Label',
    seoDescription:
      'Підкресліть свій стиль і зручність з нашими шкіряними дублянками. Виготовлені з якісних матеріалів, ці дублянки поєднують елегантний дизайн і відмінну теплоізоляцію',
  },
  '/en/sheepdogs': {
    category: 'sheepdogs',
    title: 'sheepdogs',
    seoH1: 'Sheepskin coats',
    seoTitle: 'Sheepskin coats made of genuine leather - Got`s Label',
    seoDescription:
      'Accentuate your style and comfort with our leather sheepskin coats. Made of quality materials, these sheepskin coats combine an elegant design with excellent thermal insulation',
  },
  '/pl/sheepdogs': {
    category: 'sheepdogs',
    title: 'owczarki',
    seoH1: 'Skórzane płaszcze z owczej skóry',
    seoTitle: 'Kurtka z owczej skóry wykonana ze skóry naturalnej - Got`s Label',
    seoDescription:
      'Podkreśl swój styl i wygodę dzięki naszym skórzanym płaszczom z owczej skóry. Wykonane z wysokiej jakości materiałów te kożuchy łączą elegancki design z doskonałą izolacją termiczną',
  },
  '/coats': {
    category: 'coats',
    title: 'шуби',
    seoH1: 'Пальта шкіряні',
    seoTitle: 'Шкіряні пальта - Got`s Label',
    seoDescription:
      'Відчуйте елегантність і тепло з нашими шкіряними пальтами. Наша колекція включає вишукані моделі, які поєднують класичний стиль і сучасний комфорт',
  },
  '/en/coats': {
    category: 'coats',
    title: 'coats',
    seoH1: 'Leather coats',
    seoTitle: 'Leather coats - Got`s Label',
    seoDescription:
      'Feel elegant and warm with our leather coats. Our collection includes exquisite models that combine classic style and modern comfort',
  },
  '/pl/coats': {
    category: 'coats',
    title: 'płaszcze',
    seoH1: 'Skórzane płaszcze',
    seoTitle: 'Płaszcze skórzane - Got`s Label',
    seoDescription:
      'Poczuj się elegancko i ciepło dzięki naszym skórzanym płaszczom. W naszej kolekcji znajdziesz wykwintne modele, które łączą w sobie klasykę i nowoczesny komfort',
  },
  '/dresses': {
    category: 'dresses',
    title: 'сукні',
    seoH1: 'Шкіярні сукні',
    seoTitle: 'Шкіярні сукні для яскравих образів - Got`s Label',
    seoDescription: 'Шкіряні сукні від нашого бренду подарують жіночність та спокусливість твоєму образу. Замовляй і будь в тренді',
    seoLang: '/dresses',
  },
  '/en/dresses': {
    category: 'dresses',
    title: 'dresses',
    seoH1: 'Leather dresses',
    seoTitle: 'Leather dresses for bright images - Got`s Label',
    seoDescription: 'Leather dresses from our brand will add femininity and seduction to your image. Order and be in trend',
  },
  '/pl/dresses': {
    category: 'dresses',
    title: 'sukienki',
    seoH1: 'Sukienki skórzane',
    seoTitle: 'Skórzane sukienki dla jasnych obrazów - Got`s Label',
    seoDescription: 'Sukienki skórzane naszej marki dodadzą Twojemu wizerunkowi kobiecości i uwodzicielstwa. Zamów i bądź trendy',
  },
  '/pants': {
    category: 'pants',
    title: 'штани',
    seoH1: 'Шкіряні штани',
    seoTitle: 'Стильні штани з натуральної шкіри - Got`s Label',
    seoDescription:
      'Внесіть стиль і розкіш у свій гардероб з нашими шкіряними штанами. Виготовлені з високоякісних матеріалів, ці штани забезпечують ідеальне поєднання комфорту та елегантності',
  },
  '/en/pants': {
    category: 'pants',
    title: 'pants',
    seoH1: 'Leather pants',
    seoTitle: 'Stylish pants made of genuine leather - Got`s Label',
    seoDescription:
      'Add style and luxury to your wardrobe with our leather trousers. Crafted from high-quality materials, these pants provide the perfect combination of comfort and elegance',
  },
  '/pl/pants': {
    category: 'pants',
    title: 'spodnie',
    seoH1: 'Skórzane spodnie',
    seoTitle: 'Stylowe spodnie wykonane ze skóry naturalnej - Got`s Label',
    seoDescription:
      'Dodaj stylu i luksusu do swojej garderoby dzięki naszym skórzanym spodniom. Spodnie te wykonane z wysokiej jakości materiałów stanowią idealne połączenie wygody i elegancji',
  },
  '/shorts': {
    category: 'shorts',
    title: 'шорти',
    seoH1: 'Шкіряні шорти',
    seoTitle: 'Ассортимент шкіряних шортів - Got`s Label',
    seoDescription:
      'Оновіть свій стиль з нашими шкіряними шортами! Ідеальні для будь-якого сезону, ці шорти поєднують елегантність і сучасний дизайн',
  },
  '/en/shorts': {
    category: 'shorts',
    title: 'shorts',
    seoH1: 'Leather shorts',
    seoTitle: 'Assortment of leather shorts - Got`s Label',
    seoDescription: 'Update your style with our leather shorts! Perfect for any season, these shorts combine elegance and modern design',
  },
  '/pl/shorts': {
    category: 'shorts',
    title: 'szorty',
    seoH1: 'Skórzane szorty',
    seoTitle: 'Asortyment spodenek skórzanych - Got`s Label',
    seoDescription:
      'Odśwież swój styl dzięki naszym skórzanym spodenkom! Idealne na każdą porę roku, te spodenki łączą w sobie elegancję i nowoczesny design',
  },
  '/skirts': {
    category: 'skirts',
    title: 'спідниці',
    seoH1: 'Шкіярні спідниці',
    seoTitle: 'Брендові спідниці з якісної шкіри - Got`s Label',
    seoDescription: 'Ознайомся з нашим широким ассортиментом шкіряних спідниць та вдоскональ свій образ',
  },
  '/en/skirts': {
    category: 'skirts',
    title: 'skirts',
    seoH1: 'Leather skirts',
    seoTitle: 'Branded skirts made of high-quality leather - Got`s Label',
    seoDescription: 'Check out our wide range of leather skirts and perfect your look',
  },
  '/pl/skirts': {
    category: 'skirts',
    title: 'jupe',
    seoH1: 'Skórzane spódnice',
    seoTitle: 'Markowe spódnice wykonane z wysokiej jakości skóry - Got`s Label',
    seoDescription: 'Sprawdź naszą szeroką ofertę spódnic skórzanych i doskonal swój wygląd',
  },
  '/jeans': {
    category: 'jeans',
    title: 'джинси',
    seoH1: 'Каталог джинсів',
    seoTitle: 'Зручні та практичні джинси - Got`s Label',
    seoDescription:
      'Знайдіть ідеальні зручні та практичні джинси для будь-якого випадку. Наш асортимент включає моделі, які поєднують комфорт, функціональність і сучасний стиль. Оберіть джинси, що ідеально сидять та підходять до вашого способу життя!',
  },
  '/en/jeans': {
    category: 'jeans',
    title: 'jeans',
    seoH1: 'Catalog of jeans',
    seoTitle: 'Comfortable and practical jeans - Got`s Label',
    seoDescription:
      'Find the perfect pair of comfortable and practical jeans for any occasion. Our range includes models that combine comfort, functionality and modern style. Choose jeans that fit perfectly and suit your lifestyle!',
  },
  '/pl/jeans': {
    category: 'jeans',
    title: 'jeansy',
    seoH1: 'Katalog dżinsów',
    seoTitle: 'Wygodne i praktyczne jeansy - Got`s Label',
    seoDescription:
      'Znajdź idealną parę wygodnych i praktycznych dżinsów na każdą okazję. W naszej ofercie znajdują się modele, które łączą w sobie wygodę, funkcjonalność i nowoczesny styl. Wybierz dżinsy, które idealnie pasują i pasują do Twojego stylu życia!',
  },
  '/all-products': {
    title: 'усі товари',
    seoH1: 'Весь ассортимент ',
    seoTitle: 'Весь ассортимент шкіряного одягу та виробів - Got`s Label',
    seoDescription:
      'Огляньте наш повний асортимент шкіряного одягу та виробів, включаючи стильні куртки, елегантні пальто та ексклюзивні аксесуари. Ми пропонуємо тільки найкращі матеріали та бездоганну якість',
  },
  '/en/all-products': {
    title: 'all products',
    seoH1: 'The whole assortment',
    seoTitle: 'The entire range of leather clothing and products - Got`s Label',
    seoDescription:
      'Browse our full range of leather clothing and accessories, including stylish jackets, elegant coats and exclusive accessories. We offer only the best materials and impeccable quality',
  },
  '/pl/all-products': {
    title: 'wszystkie towary',
    seoH1: 'Cały asortyment',
    seoTitle: 'Cała oferta odzieży i produktów skórzanych - Got`s Label',
    seoDescription:
      'Przeglądaj naszą pełną ofertę odzieży i akcesoriów skórzanych, w tym stylowe kurtki, eleganckie płaszcze i ekskluzywne akcesoria. Oferujemy wyłącznie najlepsze materiały i nienaganną jakość',
  },
  '/new-collection': {
    collection: 'new-collection',
    title: 'new collection',
    seoH1: 'Нова колекція',
    seoTitle: 'Нова колекція шкіряного одягу - Got`s Label',
    seoDescription:
      'Відкрийте нову колекцію шкіряного одягу, де стиль і інновації зустрічаються. Наші свіжі моделі включають вишукані куртки, косухи та жакети',
  },
  '/en/new-collection': {
    collection: 'new-collection',
    title: 'new collection',
    seoH1: 'New collection',
    seoTitle: 'New collection of leather clothes - Got`s Label',
    seoDescription:
      'Discover a new collection of leather clothing where style and innovation meet. Our fresh styles include sophisticated jackets, ponchos and jackets',
  },
  '/pl/new-collection': {
    collection: 'new-collection',
    title: 'nowa kolekcja',
    seoH1: 'Nowa kolekcja',
    seoTitle: 'Nowa kolekcja odzieży skórzanej - Got`s Label',
    seoDescription:
      'Odkryj nową kolekcję odzieży skórzanej, w której spotykają się styl i innowacja. Wśród naszych świeżych stylów znajdują się wyrafinowane kurtki, poncza i kurtki',
  },
  '/winter-essentials': {
    collection: 'winter-essentials',
    title: 'winter essentials',
    seoH1: 'Зимовий одяг',
    seoTitle: 'Зимовий шкіряний одяг - Got`s Label',
    seoDescription:
      'Зустрічайте зиму в стилі з нашим зимовим шкіряним одягом! Наша колекція включає теплий та елегантний одяг що забезпечить вам комфорт і захист від холоду.',
  },
  '/en/winter-essentials': {
    collection: 'winter-essentials',
    title: 'winter essentials',
    seoH1: 'Winter clothes',
    seoTitle: 'Winter leather clothing - Got`s Label',
    seoDescription:
      'Meet winter in style with our winter leather clothing! Our collection includes warm and elegant clothes that will provide you with comfort and protection from the cold.',
  },
  '/pl/winter-essentials': {
    collection: 'winter-essentials',
    title: 'zimowe niezbędniki',
    seoH1: 'Zimowe ubrania',
    seoTitle: 'Zimowa odzież skórzana - Got`s Label',
    seoDescription:
      'Powitaj zimę w wielkim stylu dzięki naszej zimowej odzieży skórzanej! W naszej kolekcji znajdziesz ciepłe i eleganckie ubrania, które zapewnią Ci wygodę i ochronę przed zimnem.',
  },
  '/jackets-23-24': {
    collection: 'jackets',
    title: 'jackets 23 - 24',
    seoH1: 'Куртки та косухи сезону 2023 - 2024',
    seoTitle: 'Шкіряні куртки та косухи сезону 2023 - 2024 - Got`s Label',
    seoDescription:
      'Відкрийте для себе стильні шкіряні куртки та косухи сезону 2023-2024. Наша нова колекція пропонує сучасні дизайни та високоякісні матеріали',
  },
  '/en/jackets-23-24': {
    collection: 'jackets',
    title: 'jackets 23 - 24',
    seoH1: 'Jackets and coats of the 2023 - 2024 season',
    seoTitle: 'Leather jackets and coats of the 2023 - 2024 season - Got`s Label',
    seoDescription:
      'Discover stylish leather jackets and coats for the 2023-2024 season. Our new collection offers modern designs and high quality materials',
  },
  '/pl/jackets-23-24': {
    collection: 'jackets',
    title: 'jackets 23 - 24',
    seoH1: 'Kurtki i płaszcze sezonu 2023 - 2024',
    seoTitle: 'Kurtki i płaszcze skórzane na sezon 2023 - 2024 - Got`s Label',
    seoDescription:
      'Odkryj stylowe skórzane kurtki i płaszcze na sezon 2023-2024. Nasza nowa kolekcja charakteryzuje się nowoczesnymi wzorami i wysokiej jakości materiałami',
  },
};

export const categories = {
  ukr: [
    { value: 'jackets', label: 'Куртки' },
    { value: 'bombers', label: 'Бомбери' },
    { value: 'vests', label: 'Жилети' },
    { value: 'trench', label: 'Тренчі' },
    { value: 'sheepdogs', label: 'Дублянки' },
    { value: 'coats', label: 'Шуби' },
    { value: 'dresses', label: 'Плаття' },
    { value: 'pants', label: 'Штани' },
    { value: 'shorts', label: 'Шорти' },
    { value: 'skirts', label: 'Cпідниці' },
    { value: 'jeans', label: 'Джинси' },
    { value: 'all', label: 'всі' },
  ],
  eng: [
    { value: 'jackets', label: 'Jackets' },
    { value: 'bombers', label: 'bombers' },
    { value: 'vests', label: 'vests' },
    { value: 'trench', label: 'trench' },
    { value: 'sheepdogs', label: 'sheepdogs' },
    { value: 'coats', label: 'coats' },
    { value: 'dresses', label: 'dresses' },
    { value: 'pants', label: 'pants' },
    { value: 'shorts', label: 'shorts' },
    { value: 'skirts', label: 'skirts' },
    { value: 'jeans', label: 'jeans' },
    { value: 'all', label: 'all' },
  ],
  pl: [
    { value: 'jackets', label: 'Kurtki' },
    { value: 'bombers', label: 'Bomberki' },
    { value: 'vests', label: 'Kamizelki' },
    { value: 'trench', label: 'Plaszcze trencz' },
    { value: 'sheepdogs', label: 'Płaszcze' },
    { value: 'coats', label: 'Płaszcze' },
    { value: 'dresses', label: 'Sukienki' },
    { value: 'pants', label: 'Spodnie' },
    { value: 'shorts', label: 'Szorty' },
    { value: 'skirts', label: 'Spódnice' },
    { value: 'jeans', label: 'Jeansy' },
    { value: 'all', label: 'Wszystkie' },
  ],
};

export const colors = {
  ukr: [
    { value: 'white', label: 'Білий' },
    { value: 'black', label: 'Чорний' },
    { value: 'grey', label: 'Сірий' },
    { value: 'vintage_grey', label: 'Сірий вінтажний' },
    { value: 'suede_grey', label: 'Сірий замшевий' },
    { value: 'blue', label: 'Голубий' },
    { value: 'pink', label: 'Рожевий' },
    { value: 'burgundy', label: 'Бордовий' },
    { value: 'khaki', label: 'Хакі' },
    { value: 'brown', label: 'Коричневий' },
    { value: 'brownVintage', label: 'Коричневий вінтажний' },
    { value: 'beige', label: 'Бежевий' },
    { value: 'ginger', label: 'Рудий' },
    { value: 'pistachio', label: 'Фісташковий' },
    { value: 'sea', label: 'Морська хвиля' },
    { value: 'caramel', label: 'Карамельний' },
    { value: 'black_vintage', label: 'Чорний вінтажний' },
    { value: 'plum', label: 'Сливовий' },
  ],
  eng: [
    { value: 'white', label: 'White' },
    { value: 'black', label: 'Black' },
    { value: 'grey', label: 'Grey' },
    { value: 'vintage_grey', label: 'Vintage grey' },
    { value: 'suede_grey', label: 'Suede grey' },
    { value: 'blue', label: 'Blue' },
    { value: 'pink', label: 'Pink' },
    { value: 'burgundy', label: 'Burgundy' },
    { value: 'khaki', label: 'Khaki' },
    { value: 'brown', label: 'Brown' },
    { value: 'brownVintage', label: 'Brown vintage' },
    { value: 'beige', label: 'Beige' },
    { value: 'ginger', label: 'Ginger' },
    { value: 'pistachio', label: 'Pistachio' },
    { value: 'sea', label: 'Sea wave' },
    { value: 'caramel', label: 'Caramel' },
    { value: 'black_vintage', label: 'Black vintage' },
    { value: 'plum', label: 'Plum' },
  ],
  pl: [
    { value: 'white', label: 'Biały' },
    { value: 'black', label: 'Czarny' },
    { value: 'grey', label: 'Szary' },
    { value: 'vintage_grey', label: 'Szary vintage' },
    { value: 'suede_grey', label: 'Szary zamszowy' },
    { value: 'blue', label: 'Niebieski' },
    { value: 'pink', label: 'Różowy' },
    { value: 'burgundy', label: 'Bordowy' },
    { value: 'khaki', label: 'Khaki' },
    { value: 'brown', label: 'Brązowy' },
    { value: 'brownVintage', label: 'Brązowy vintage' },
    { value: 'beige', label: 'Beżowy' },
    { value: 'ginger', label: 'Rudy' },
    { value: 'pistachio', label: 'Pistacja' },
    { value: 'sea', label: 'Fala morska' },
    { value: 'caramel', label: 'Karmel' },
    { value: 'black_vintage', label: 'Czarny rocznik' },
    { value: 'plum', label: 'Śliwka' },
  ],
};

export const skin = {
  ukr: [
    { value: 'smooth', label: 'Гладка' },
    { value: 'jumbo', label: 'Джумбо' },
    { value: 'lakova', label: 'Лакова' },
    { value: 'vintage', label: 'Вінтажна' },
    { value: 'crocodile', label: 'З тисненням крокодила' },
    { value: 'suede', label: 'Замшева' },
    { value: 'lama', label: 'Хутро лами' },
    { value: 'tigrado', label: 'Tigrado' },
    { value: 'sheepskin', label: 'Овчина' },
  ],
  eng: [
    { value: 'smooth', label: 'smooth' },
    { value: 'jumbo', label: 'jumbo' },
    { value: 'lakova', label: 'lakova' },
    { value: 'vintage', label: 'vintage' },
    { value: 'crocodile', label: 'crocodile' },
    { value: 'suede', label: 'suede' },
    { value: 'lama', label: 'Llama fur' },
    { value: 'tigrado', label: 'Tigrado' },
    { value: 'sheepskin', label: 'Sheepskin' },
  ],
  pl: [
    { value: 'smooth', label: 'Gładka' },
    { value: 'jumbo', label: 'Jumbo' },
    { value: 'lakova', label: 'Lakierowana' },
    { value: 'vintage', label: 'Wintage' },
    { value: 'crocodile', label: 'Z tłoczonym krokodylem' },
    { value: 'suede', label: 'Zamszowa' },
    { value: 'lama', label: 'Futra lamy' },
    { value: 'tigrado', label: 'Tigrado' },
    { value: 'sheepskin', label: 'Owcza skóra' },
  ],
};
