import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Container from './Container/Container';
import Main from './Main/Main';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import { Context } from '../Context';
import PublicOffer from './PublicOffer/PublicOffer';
import About from './About/About';
import Faqs from './Faqs/Faqs';
import Contacts from './Contacts/Contacts';
import DeliveryPayment from './DeliveryPayment/DeliveryPayment';
import Payment from './Payment/Payment';
import Category from './Category/Category';
import Product from './Product/Product';
import ThanksPage from './ThanksPage/ThanksPage';
import Admin from './Admin/Admin';
import Setting from './Admin/Setting/Setting';
import CookiePolicy from './CookiePolicy/CookiePolicy';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import NotFound from './NotFound/NotFound';

export default function MainRoute() {
  const { setSelectedItems, saveLocation, setActiveButton } = useContext(Context);
  const location = useLocation();

  // сбрасываем фильтр после изменения ссылки
  useEffect(() => {
    if (
      location.pathname !== '/product' &&
      location.pathname !== '/new-collection' &&
      location.pathname !== '/winter-essentials' &&
      location.pathname !== '/leather-23-24' &&
      location.pathname !== saveLocation
    ) {
      setSelectedItems({ color: [], skin: [], category: [], number: 0 });
      setActiveButton('all');
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <Container>
      <ScrollToTop />
      <Routes>
        <Route path="/*" element={<NotFound />} />
        <Route path="/not-found" element={<NotFound />} />

        <Route path="/" element={<Main />} />
        <Route path="/en" element={<Main />} />
        <Route path="/pl" element={<Main />} />

        <Route path="/admin" element={<Admin />} />
        <Route path="/en/admin" element={<Admin />} />
        <Route path="/pl/admin" element={<Admin />} />

        <Route path="/setting/:id" element={<Product />} />
        <Route path="/en/setting/:id" element={<Product />} />
        <Route path="/pl/setting/:id" element={<Product />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/en/setting" element={<Setting />} />
        <Route path="/pl/setting" element={<Setting />} />

        <Route path="/contacts/:id" element={<Product />} />
        <Route path="/en/contacts/:id" element={<Product />} />
        <Route path="/pl/contacts/:id" element={<Product />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/en/contacts" element={<Contacts />} />
        <Route path="/pl/contacts" element={<Contacts />} />

        <Route path="/about-us/:id" element={<Product />} />
        <Route path="/en/about-us/:id" element={<Product />} />
        <Route path="/pl/about-us/:id" element={<Product />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/en/about-us" element={<About />} />
        <Route path="/pl/about-us" element={<About />} />

        {/* <Route path="/product" element={<Product />} />
        <Route path="/en/product" element={<Product />} />
        <Route path="/pl/product" element={<Product />} /> */}

        <Route path="/sale/:id" element={<Product />} />
        <Route path="/en/sale/:id" element={<Product />} />
        <Route path="/pl/sale/:id" element={<Product />} />
        <Route path="/sale" element={<Category />} />
        <Route path="/en/sale" element={<Category />} />
        <Route path="/pl/sale" element={<Category />} />

        <Route path="/new/:id" element={<Product />} />
        <Route path="/en/new/:id" element={<Product />} />
        <Route path="/pl/new/:id" element={<Product />} />
        <Route path="/new" element={<Category />} />
        <Route path="/en/new" element={<Category />} />
        <Route path="/pl/new" element={<Category />} />

        <Route path="/jackets/:id" element={<Product />} />
        <Route path="/en/jackets/:id" element={<Product />} />
        <Route path="/pl/jackets/:id" element={<Product />} />
        <Route path="/jackets" element={<Category />} />
        <Route path="/en/jackets" element={<Category />} />
        <Route path="/pl/jackets" element={<Category />} />

        <Route path="/bombers/:id" element={<Product />} />
        <Route path="/en/bombers/:id" element={<Product />} />
        <Route path="/pl/bombers/:id" element={<Product />} />
        <Route path="/bombers" element={<Category />} />
        <Route path="/en/bombers" element={<Category />} />
        <Route path="/pl/bombers" element={<Category />} />

        <Route path="/vests/:id" element={<Product />} />
        <Route path="/en/vests/:id" element={<Product />} />
        <Route path="/pl/vests/:id" element={<Product />} />
        <Route path="/vests" element={<Category />} />
        <Route path="/en/vests" element={<Category />} />
        <Route path="/pl/vests" element={<Category />} />

        <Route path="/trench/:id" element={<Product />} />
        <Route path="/en/trench/:id" element={<Product />} />
        <Route path="/pl/trench/:id" element={<Product />} />
        <Route path="/trench" element={<Category />} />
        <Route path="/en/trench" element={<Category />} />
        <Route path="/pl/trench" element={<Category />} />

        <Route path="/sheepdogs/:id" element={<Product />} />
        <Route path="/en/sheepdogs/:id" element={<Product />} />
        <Route path="/pl/sheepdogs/:id" element={<Product />} />
        <Route path="/sheepdogs" element={<Category />} />
        <Route path="/en/sheepdogs" element={<Category />} />
        <Route path="/pl/sheepdogs" element={<Category />} />

        <Route path="/coats/:id" element={<Product />} />
        <Route path="/en/coats/:id" element={<Product />} />
        <Route path="/pl/coats/:id" element={<Product />} />
        <Route path="/coats" element={<Category />} />
        <Route path="/en/coats" element={<Category />} />
        <Route path="/pl/coats" element={<Category />} />

        <Route path="/dresses/:id" element={<Product />} />
        <Route path="/en/dresses/:id" element={<Product />} />
        <Route path="/pl/dresses/:id" element={<Product />} />
        <Route path="/dresses" element={<Category />} />
        <Route path="/en/dresses" element={<Category />} />
        <Route path="/pl/dresses" element={<Category />} />

        <Route path="/pants/:id" element={<Product />} />
        <Route path="/en/pants/:id" element={<Product />} />
        <Route path="/pl/pants/:id" element={<Product />} />
        <Route path="/pants" element={<Category />} />
        <Route path="/en/pants" element={<Category />} />
        <Route path="/pl/pants" element={<Category />} />

        <Route path="/shorts/:id" element={<Product />} />
        <Route path="/en/shorts/:id" element={<Product />} />
        <Route path="/pl/shorts/:id" element={<Product />} />
        <Route path="/shorts" element={<Category />} />
        <Route path="/en/shorts" element={<Category />} />
        <Route path="/pl/shorts" element={<Category />} />

        <Route path="/skirts/:id" element={<Product />} />
        <Route path="/en/skirts/:id" element={<Product />} />
        <Route path="/pl/skirts/:id" element={<Product />} />
        <Route path="/skirts" element={<Category />} />
        <Route path="/en/skirts" element={<Category />} />
        <Route path="/pl/skirts" element={<Category />} />

        <Route path="/jeans/:id" element={<Product />} />
        <Route path="/en/jeans/:id" element={<Product />} />
        <Route path="/pl/jeans/:id" element={<Product />} />
        <Route path="/jeans" element={<Category />} />
        <Route path="/en/jeans" element={<Category />} />
        <Route path="/pl/jeans" element={<Category />} />

        <Route path="/new-collection/:id" element={<Product />} />
        <Route path="/en/new-collection/:id" element={<Product />} />
        <Route path="/pl/new-collection/:id" element={<Product />} />
        <Route path="/new-collection" element={<Category />} />
        <Route path="/en/new-collection" element={<Category />} />
        <Route path="/pl/new-collection" element={<Category />} />

        <Route path="/winter-essentials/:id" element={<Product />} />
        <Route path="/en/winter-essentials/:id" element={<Product />} />
        <Route path="/pl/winter-essentials/:id" element={<Product />} />
        <Route path="/winter-essentials" element={<Category />} />
        <Route path="/en/winter-essentials" element={<Category />} />
        <Route path="/pl/winter-essentials" element={<Category />} />

        <Route path="/jackets-23-24/:id" element={<Product />} />
        <Route path="/en/jackets-23-24/:id" element={<Product />} />
        <Route path="/pl/jackets-23-24/:id" element={<Product />} />
        <Route path="/jackets-23-24" element={<Category />} />
        <Route path="/en/jackets-23-24" element={<Category />} />
        <Route path="/pl/jackets-23-24" element={<Category />} />

        <Route path="/all-products/:id" element={<Product />} />
        <Route path="/en/all-products/:id" element={<Product />} />
        <Route path="/pl/all-products/:id" element={<Product />} />
        <Route path="/all-products" element={<Category />} />
        <Route path="/en/all-products" element={<Category />} />
        <Route path="/pl/all-products" element={<Category />} />

        <Route path="/bestsellers/:id" element={<Product />} />
        <Route path="/en/bestsellers/:id" element={<Product />} />
        <Route path="/pl/bestsellers/:id" element={<Product />} />
        <Route path="/bestsellers" element={<Category />} />
        <Route path="/en/bestsellers" element={<Category />} />
        <Route path="/pl/bestsellers" element={<Category />} />

        <Route path="/payment/:id" element={<Product />} />
        <Route path="/en/payment/:id" element={<Product />} />
        <Route path="/pl/payment/:id" element={<Product />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/en/payment" element={<Payment />} />
        <Route path="/pl/payment" element={<Payment />} />

        <Route path="/faq/:id" element={<Product />} />
        <Route path="/en/faq/:id" element={<Product />} />
        <Route path="/pl/faq/:id" element={<Product />} />
        <Route path="/faq" element={<Faqs />} />
        <Route path="/en/faq" element={<Faqs />} />
        <Route path="/pl/faq" element={<Faqs />} />

        <Route path="/delivery-payment/:id" element={<Product />} />
        <Route path="/en/delivery-payment/:id" element={<Product />} />
        <Route path="/pl/delivery-payment/:id" element={<Product />} />
        <Route path="/delivery-payment" element={<DeliveryPayment />} />
        <Route path="/en/delivery-payment" element={<DeliveryPayment />} />
        <Route path="/pl/delivery-payment" element={<DeliveryPayment />} />

        <Route path="/privacy-policy/:id" element={<Product />} />
        <Route path="/en/privacy-policy/:id" element={<Product />} />
        <Route path="/pl/privacy-policy/:id" element={<Product />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/en/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/pl/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/public-offer/:id" element={<Product />} />
        <Route path="/en/public-offer/:id" element={<Product />} />
        <Route path="/pl/public-offer/:id" element={<Product />} />
        <Route path="/public-offer" element={<PublicOffer />} />
        <Route path="/en/public-offer" element={<PublicOffer />} />
        <Route path="/pl/public-offer" element={<PublicOffer />} />

        <Route path="/successful-order/:id" element={<Product />} />
        <Route path="/en/successful-order/:id" element={<Product />} />
        <Route path="/pl/successful-order/:id" element={<Product />} />
        <Route path="/successful-order" element={<ThanksPage />} />
        <Route path="/en/successful-order" element={<ThanksPage />} />
        <Route path="/pl/successful-order" element={<ThanksPage />} />

        <Route path="/order-error/:id" element={<Product />} />
        <Route path="/en/order-error/:id" element={<Product />} />
        <Route path="/pl/order-error/:id" element={<Product />} />
        <Route path="/order-error" element={<ThanksPage />} />
        <Route path="/en/order-error" element={<ThanksPage />} />
        <Route path="/pl/order-error" element={<ThanksPage />} />

        <Route path="/cookie-policy/:id" element={<Product />} />
        <Route path="/en/cookie-policy/:id" element={<Product />} />
        <Route path="/pl/cookie-policy/:id" element={<Product />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/en/cookie-policy" element={<CookiePolicy />} />
        <Route path="/pl/cookie-policy" element={<CookiePolicy />} />
      </Routes>
    </Container>
  );
}
