export const menuItems = {
  ukr: [
    { label: 'КАТАЛОГ' },
    { label: 'ЗНИЖКИ %', to: '/sale' },
    { label: 'НОВИНКИ', to: '/new' },
    { label: 'ДОСТАВКА ТА ОПЛАТА', to: '/delivery-payment' },
    { label: 'КОНТАКТИ', to: '/contacts' },
    { label: "ПРО GOT'S LABEL", to: '/about-us' },
  ],
  eng: [
    { label: 'CATALOG' },
    { label: 'SALE %', to: '/en/sale' },
    { label: 'NEW IN', to: '/en/new' },
    { label: 'DELIVERY & PAYMENT', to: '/en/delivery-payment' },
    { label: 'CONTACTS', to: '/en/contacts' },
    { label: "ABOUT GOT'S LABEL", to: '/en/about-us' },
  ],
  pl: [
    { label: 'KATALOG' },
    { label: 'PRZECENA %', to: '/pl/sale' },
    { label: 'NOWOŚCI', to: '/pl/new' },
    { label: 'DOSTAWA I PŁATNOŚĆ', to: '/pl/delivery-payment' },
    { label: 'KONTAKT', to: '/pl/contacts' },
    { label: 'O NASZEJ MARCE', to: '/pl/about-us' },
  ],
};

export const categories = {
  ukr: {
    categoryOne: {
      buttons: [
        { label: 'Куртки', link: '/jackets' },
        { label: 'Бомбери', link: '/bombers' },
        // { label: 'Жилети', link: '/vests' },
        { label: 'Тренчі', link: '/trench' },
        { label: 'Дублянки', link: '/sheepdogs' },
        { label: 'Шуби', link: '/coats' },
      ],
      picture: {
        link: '/new-collection',
        src: '/img/collections/new-collection.webp',
        text: 'КОЛЕКЦІЯ ”РОЗКУТА”',
      },
    },
    categoryTwo: {
      buttons: [
        // { label: 'Джинси', link: '/jeans' },
        { label: 'Сукні', link: '/dresses' },
        // { label: 'Шорти', link: '/shorts' },
        // { label: 'Штани', link: '/pants' },
        { label: 'Cпідниці', link: '/skirts' },
      ],
      picture: {
        link: '/sale',
        src: '/img/collections/sale.webp',
        text: 'SALE UP TO 50 %',
      },
    },
  },
  eng: {
    categoryOne: {
      buttons: [
        { label: 'Jackets', link: '/en/jackets' },
        { label: 'Bombers', link: '/en/bombers' },
        // { label: 'Vests', link: '/en/vests' },
        { label: 'Trenches', link: '/en/trench' },
        { label: 'Shearlings', link: '/en/sheepdogs' },
        { label: 'Furs', link: '/en/coats' },
      ],
      picture: {
        link: '/en/new-collection',
        src: '/img/collections/new-collection.webp',
        text: 'COLLECTION “UNCHAINED”',
      },
    },
    categoryTwo: {
      buttons: [
        // { label: 'Jeans', link: '/en/jeans' },
        { label: 'Dresses', link: '/en/dresses' },
        // { label: 'Shorts', link: '/en/shorts' },
        // { label: 'Pants', link: '/en/pants' },
        { label: 'Skirts', link: '/en/skirts' },
      ],
      picture: {
        link: '/en/sale',
        src: '/img/collections/sale.webp',
        text: 'SALE UP TO 50 %',
      },
    },
  },
  pl: {
    categoryOne: {
      buttons: [
        { label: 'Kurtki', link: '/pl/jackets' },
        { label: 'Bomberki', link: '/pl/bombers' },
        // { label: 'Kamizelki', link: '/pl/vests' },
        { label: 'Płaszcze', link: '/pl/trench' },
        { label: 'Kożuchy', link: '/pl/sheepdogs' },
        { label: 'Futra', link: '/pl/coats' },
      ],
      picture: {
        link: '/pl/new-collection',
        src: '/img/collections/new-collection.webp',
        text: 'Kolekcja “UNCHAINED”',
      },
    },
    categoryTwo: {
      buttons: [
        // { label: 'Dżinsy', link: '/pl/jeans' },
        { label: 'Sukienki', link: '/pl/dresses' },
        // { label: 'Szorty', link: '/pl/shorts' },
        // { label: 'Spodnie', link: '/pl/pants' },
        { label: 'Spódnice', link: '/pl/skirts' },
      ],
      picture: {
        link: '/pl/sale',
        src: '/img/collections/sale.webp',
        text: 'SALE UP TO 50 %',
      },
    },
  },
};

export const meinLink = {
  ukr: '/',
  eng: '/en/',
  pl: '/pl/',
};
