import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import OurBestsellers from '../../components/OurBestsellers/OurBestsellers';
import ItemList from '../../components/ItemList/ItemList';
import { Context } from '../../Context';
import { filter } from '../../functions/Filter';
import { pathFilters, categories } from './CategoryInfo';
import { CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import './Category.css';

export default function Category() {
  const { data, setActiveWindow, list, setList, selectedItems, activeButton, setActiveButton, setSelectedItems, language } =
    useContext(Context);

  const [firstFourItems, setFirstFourItems] = useState([]);
  const [secondFourItems, setSecondFourItems] = useState([]);
  const [thirdFourItems, setThirdFourItems] = useState([]);
  const [otherItems, setOtherItems] = useState([]);
  const [visibleCount, setVisibleCount] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  // имя страницы
  // const [title, setTitle] = useState(true);
  // записываем коллекцию
  const [seveCollection, setSeveCollection] = useState(false);

  // рандомный баннер
  const [randomProduct, setRandomProduct] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // только для сео
  const path = location.pathname;
  const seolLink = path.slice(path.lastIndexOf('/'));

  //записуємо параметрив урлу
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const countFromQuery = parseInt(queryParams.get('visibleCount')) || 0;
    setVisibleCount(countFromQuery);
  }, [location.search]);

  let { category, collection, newSale, bestsellers, title, seoTitle, seoDescription, seoH1 } = pathFilters[location.pathname];

  // определяет страницу и фильтрет товары
  useEffect(() => {
    if (data && location) {
      // Создаем объект с фильтрами
      const filters = {
        category,
        collection,
        newSale,
        bestsellers,
      };
      let filteredProducts = data;

      if (selectedItems.number > 0) {
        if (language === 'ukr') {
          filteredProducts = filteredProducts.filter((product) => parseInt(product.priceUa, 10) <= selectedItems.number);
        } else {
          filteredProducts = filteredProducts.filter((product) => parseInt(product.priceEn, 10) <= selectedItems.number);
        }
      }

      // Добавляем выбранные цвета и/или типы кожи в фильтры
      if (activeButton !== 'all') {
        filters.category = selectedItems.category;
      } else if (Array.isArray(selectedItems.category) && selectedItems.category.length > 0) {
        filters.category = selectedItems.category;
      } else if (Array.isArray(selectedItems.category)) {
        filters.category = category;
      } else {
        filters.category = false;
      }

      if (selectedItems.color.length > 0) {
        filters.color = selectedItems.color;
      }

      if (selectedItems.skin.length > 0) {
        filters.skin = selectedItems.skin;
      }

      // Фильтруем продукты с использованием всех фильтров
      filteredProducts = filter(filteredProducts, filters);

      // const visibleProducts = filteredProducts.filter((product) => !product.hide);
      // const visibleProducts = filteredProducts.filter((product) => !product.hide).reverse();
      const visibleProducts = filteredProducts
        .filter((product) => !product.hide)
        .reverse() // Сначала делаем reverse для всех товаров
        .sort((a, b) => {
          // Проверяем наличие даты
          const hasDateA = !!a.date;
          const hasDateB = !!b.date;

          // Если у обоих товаров есть даты или у обоих их нет, оставляем порядок как есть
          if (hasDateA === hasDateB) {
            return 0;
          }

          // Если у товара A есть дата, он поднимается вверх
          return hasDateA ? -1 : 1;
        });

      setList(visibleProducts);

      // Функция для выбора случайного товара
      const randomIndex = Math.floor(Math.random() * filteredProducts.length); // Генерируем случайный индекс
      const random = filteredProducts[randomIndex]; // Получаем случайный товар
      setRandomProduct(random); // Устанавливаем выбранный товар в состояние

      // Устанавливаем заголовок и коллекцию
      // setTitle(title);
      setSeveCollection(collection || false);
    }
    // eslint-disable-next-line
  }, [data, selectedItems]);

  // фильтруем коллекции
  const handleClick = (props) => {
    if (props === 'all') {
      setSelectedItems((prevItems) => ({
        ...prevItems,
        category: false,
      }));
      setActiveButton(props);
    } else if (seveCollection && props !== 'filter') {
      setSelectedItems((prevItems) => ({
        ...prevItems,
        category: [props],
      }));
      setActiveButton(props);
    } else {
      setActiveWindow(props);
    }
  };

  useEffect(() => {
    if (list) {
      setFirstFourItems(list.slice(0, 4));
      setSecondFourItems(list.slice(4, 8));
      setThirdFourItems(list.slice(8, 20));
      setOtherItems(list.slice(20));
    }
  }, [list]);

  //кнопка показати більше
  const handleShowMore = () => {
    const newVisibleCount = visibleCount + 20;
    // Оновлення query string з новим значенням visibleCount
    navigate(`?visibleCount=${newVisibleCount}`, { replace: true });
    // Оновлення стану компонента з новою видимою кількістю товарів
    setVisibleCount(newVisibleCount);
  };

  const texts = {
    eng: {
      link: '/en',
      filter: 'filter',
      button: 'show more',
      noItems: 'no items found...',
      lang: 'en',
      main: 'Main',
    },
    ukr: {
      link: '/',
      filter: 'фільтр',
      button: 'показати більше',
      noItems: 'товарів не знайдено...',
      lang: 'uk',
      main: 'Головна',
    },
    pl: {
      link: '/pl',
      filter: 'filtr',
      button: 'pokaż więcej',
      noItems: 'nie znaleziono przedmiotów...',
      lang: 'pl',
      main: 'Główny',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return list ? (
    <div className="category">
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <link rel="canonical" href={`https://gotslabel.com${location.pathname}`} />

        {/* языки */}
        <html lang={selectedLanguageData.lang} />
        <link rel="alternate" hreflang="uk" href={`https://gotslabel.com${seolLink}`} />
        <link rel="alternate" hreflang="pl" href={`https://gotslabel.com/pl${seolLink}`} />
        <link rel="alternate" hreflang="en" href={`https://gotslabel.com/en${seolLink}`} />
        <link rel="alternate" hreflang="x-default" href={`https://gotslabel.com${location.pathname}`} />
      </Helmet>

      <div className="category__menu">
        <h1 className="seoOff">{seoH1}</h1>

        <nav aria-label="Breadcrumb">
          <ul className="category__menu-title">
            <li>
              <Link to={selectedLanguageData.link}>{selectedLanguageData.main}</Link>
            </li>
            <li>/</li>
            <li>{title}</li>
          </ul>
        </nav>

        {seveCollection && (
          <div className="category__menu-list-search-wrap">
            <ul className="category__menu-list">
              {language &&
                categories[language].map((item, index) => (
                  <li className="category__menu-list-item" key={index}>
                    <button
                      type="button"
                      className={`category__menu-list-item-button ${activeButton === item.value ? 'active-button' : ''}`}
                      onClick={() => handleClick(item.value)}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
      <div className="category__filter">
        <button type="button" onClick={() => handleClick('filter')} className="category__filter-button">
          {selectedLanguageData.filter}
          <img src="/img/main/filter.svg" alt="filter" className="category__filter-button-icon" />
        </button>
      </div>

      <div className="categoty_product">
        {list.length > 0 ? (
          <ItemList list={firstFourItems} category={category} />
        ) : (
          <div className="categoty_product-notFound">{selectedLanguageData.noItems}</div>
        )}
        {list.length > 4 && list.length < 7 ? <ItemList list={secondFourItems} /> : false}

        {list.length > 6 && randomProduct ? (
          <div className="categoty_product-center mobile-category">
            {' '}
            <Link
              to={`${location.pathname}/${randomProduct.titleEn.toLowerCase().replace(/\s+/g, '-')}-${randomProduct.id}`}
              className="categoty_product-center-img-wrap"
            >
              <img src={randomProduct.img} alt="product" className="categoty_product-center-img" />
            </Link>
            <ItemList list={secondFourItems} />
          </div>
        ) : (
          false
        )}
        {list.length > 6 && randomProduct ? (
          <Link
            to={`${location.pathname}/${randomProduct.titleEn.toLowerCase().replace(/\s+/g, '-')}-${randomProduct.id}`}
            className="categoty_product-center-img-wrap--mobile laptop"
          >
            <img src={randomProduct.img} alt="product" className="categoty_product-center-img" />
          </Link>
        ) : (
          false
        )}
        {list.length > 6 && isMobile ? <ItemList list={secondFourItems} className=".item-list__two-two" /> : false}
        {list.length > 8 && isMobile ? <ItemList list={thirdFourItems} secondaryStyle=".item-list__two-two" /> : false}

        {list.length > 8 && !isMobile ? <ItemList list={thirdFourItems} secondaryStyle=".item-list__two-two" /> : false}
        {visibleCount === 0 ? false : <ItemList list={otherItems.slice(0, visibleCount)} />}
        {visibleCount < otherItems.length ? (
          <div className="categoty_product-bottom">
            <button type="button" className="categoty_product-bottom-button" onClick={handleShowMore}>
              {selectedLanguageData.button}
            </button>
          </div>
        ) : (
          false
        )}
      </div>

      <OurBestsellers />
    </div>
  ) : (
    <div className="load">
      <CircularProgress color="inherit" />
    </div>
  );
}
